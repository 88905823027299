const trimString = str => str.replace(/^\s+|\s+$/g, '');
const defaultStringify = option => `${option.label} [${option.data && option.data.numTranspondeur ? option.data.numTranspondeur : ''}]`;

export default function searchFilter (search, searchable) {
    search = trimString(search).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    // Si la recherche est numérique, on cherche un n° de transpondeur (entouré de [])
    if (/^[0-9]+$/.test(search)) {
        search = `[${search}]`
    }
    let searchableString = trimString(defaultStringify(searchable)).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    return searchableString.includes(search)
}