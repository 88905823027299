import React from 'react'
import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import "moment/locale/fr";

class PartialLicence extends React.Component{
    constructor(props) {
        super(props)

        this.licenceTypes = [
            {
                value: 'BMC',
                label: 'Assurance BMC'
            },
            {
                value: 'FFM',
                label: 'Licence FFM'
            },
            {
                value: 'ROUTE',
                label: 'Assurance Route'
            }
        ]
    }

    submitChanges(assuranceInfos) {
        this.props.handleChange(Object.assign({}, this.props.currentParticipant, assuranceInfos))
    }

    handleChange(key, e) {
        this.submitChanges({
            [key]: e.target.value
        })
    }

    handleChangeAssurance(e) {
        let assuranceExpiration = this.props.currentParticipant.assuranceExpiration
        const assurance = e.target.value

        if (!this.isValidAssurance(assurance)) {
            return
        }

        if ((e.target.value === 'FFM' || e.target.value === 'ROUTE') && (assuranceExpiration === null || (typeof assuranceExpiration === "string" && assuranceExpiration.length === 0))) {
            assuranceExpiration = moment(new Date(new Date().getFullYear(), 11, 31)).format('YYYY-MM-DD')
        }

        this.submitChanges({
            assuranceExpiration,
            assurance
        })
    }

    handleDateChange(date) {
        this.submitChanges({
            assuranceExpiration: date
        })
    }

    isValidAssurance(assurance) {
        return this.licenceTypes.find( licenceType => licenceType.value === assurance) !== undefined
    }

    // isValidDepencies() {
    //     if (this.state.assurance === 'ROUTE' || this.state.assurance === 'FFM') {
    //         if (this.state.assurance === 'ROUTE' && (!this.state.assurance_compagnie || this.state.assurance_compagnie.length === 0)) {
    //             return false
    //         }
    //
    //         return this.state.assurance_police !== null && this.state.assurance_police.length > 0 && moment(this.state.assurance_expiration).isValid()
    //     }
    //     return true
    // }

    render() {
        const assuranceExpiration = moment(this.props.currentParticipant.assuranceExpiration).isValid() ? moment(this.props.currentParticipant.assuranceExpiration).format('YYYY-MM-DD') : ''
        return (
            <React.Fragment>
                <Typography component="h1" variant="h5" gutterBottom>
                    Assurance / Licence*
                </Typography>
                <FormControl fullWidth error={this.props.currentParticipant.assurance === null}>
                    <InputLabel htmlFor="newoption-native-helper">Type de licence</InputLabel>
                    <NativeSelect
                        value={this.props.currentParticipant.assurance || ''}
                        onChange={this.handleChangeAssurance.bind(this)}
                        input={<Input name="assuranceref" id="assurance-native-helper"/>}
                        required
                    >
                        <option key={0} value=""/>
                        {this.licenceTypes.map(function (item) {
                            return (
                                <option key={item.value} value={item.value}>{ item.label }</option>
                            )
                        })}
                    </NativeSelect>
                </FormControl>
                {this.props.currentParticipant.assurance === 'ROUTE' &&
                    <TextField
                        id="resas-assurance-route"
                        label="Compagnie d'assurance"
                        error={!this.props.currentParticipant.assuranceCompagnie}
                        value={this.props.currentParticipant.assuranceCompagnie || ''}
                        required
                        onChange={this.handleChange.bind(this, 'assuranceCompagnie')}
                        margin="normal"
                        fullWidth
                    />
                }
                { this.props.currentParticipant.assurance === 'FFM' &&
                    <TextField
                        id="resas-numlicence"
                        label="Numéro de licence"
                        error={!this.props.currentParticipant.assurancePolice}
                        value={this.props.currentParticipant.assurancePolice || ''}
                        required
                        onChange={this.handleChange.bind(this, 'assurancePolice')}
                        margin="normal"
                        fullWidth
                    />
                }
                {(this.props.currentParticipant.assurance === 'ROUTE' || this.props.currentParticipant.assurance === 'FFM') &&
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'fr'}>
                        <DatePicker
                            value={assuranceExpiration}
                            onChange={this.handleDateChange.bind(this)}
                            format="DD/MM/YYYY"
                            label="Date d'expiration"
                            // variant="inline"
                            fullWidth
                            margin={'normal'}
                            clearable
                            cancelLabel={'Annuler'}
                            clearLabel={'Effacer'}
                            // initialFocusedDate={new Date(new Date().getFullYear(), 11, 31)}
                            error={(this.props.currentParticipant.assurance === 'FFM' || this.props.currentParticipant.assurance === 'ROUTE') && !moment(this.props.currentParticipant.assuranceExpiration).isValid()}
                        />
                    </MuiPickersUtilsProvider>
                }

            </React.Fragment>
        )
    }
}

export default PartialLicence