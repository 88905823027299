import {AJAX_FAILURE, AJAX_REQUEST, AJAX_SUCCESS} from "../constants/actions";
import {reloadParticipant} from "./participants";
import {reloadTraining} from "./trainings";
import {batch} from "react-redux";

export function addOption(option) {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        let apiUrl = `${process.env.REACT_APP_API_URL}/resas_opts`
        let method = 'POST'

        // Calculate price of the option extra charge
        const parentOption = store.currentTraining.options.find(function (item) {
            return item['@id'] === option.stagesOpt
        })

        option.amount = option.amount ? option.amount + (option.resasOptQte * parentOption.stagesOptPrix) : option.resasOptQte * parentOption.stagesOptPrix;
        option.onTheSpot = true;

        // Check if we add or update an option
        const existingOption = store.currentParticipant.resasOpt.find(function (item) {
            return item.stagesOpt === option.stagesOpt
        })

        if (existingOption) {
            apiUrl = `${process.env.REACT_APP_API_BASEURL}${existingOption["@id"]}`
            method = 'PUT'
            option.resasOptQte += existingOption.resasOptQte
        }

        dispatch({type: AJAX_REQUEST})
        fetch(apiUrl, {
            method,
            withCredentials: true,
            headers,
            body: JSON.stringify(option)
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            batch(() => {
                dispatch(reloadTraining())
                dispatch(reloadParticipant())
            })
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}

export function removeOption(id) {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        fetch(`${process.env.REACT_APP_API_BASEURL}${id}`, {
            method: 'DELETE',
            withCredentials: true,
            headers
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                batch(() => {
                    dispatch(reloadTraining())
                    dispatch(reloadParticipant())
                })
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }

}