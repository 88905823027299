import {
    AJAX_FAILURE,
    AJAX_REQUEST,
    AJAX_SUCCESS, CLIENT_DOC_SUCCESS, MODE_EDIT,
    PARTICIPANT_LIST_SUCCESS, PARTICIPANT_RESET,
    PARTICIPANT_SUCCESS
} from "../constants/actions";
import {batch} from "react-redux";
import {changeMode} from "./mode";


export function loadParticipantsList() {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        let url = `${process.env.REACT_APP_API_URL}/resas?articles.articlesId=${store.currentTraining.id}&resasValid=Y`
        if (store.currentTraining.multiDays && store.currentTraining.currentDay !== null) {
            url = `${url}&trainingStart[before]=${store.currentTraining.currentDay}&trainingEnd[after]=${store.currentTraining.currentDay}`
        }
        return fetch(url, {
            method: 'GET',
            withCredentials: true,
            headers
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            const participants = data["hydra:member"].map( function (participant) {
                let name = 'Emplacement vide'
                if (participant.resasNom !== null || participant.resasPrenom !== null || participant.resasEmail !== null) {
                    const nameCompose = []
                    if (participant.resasNom !== null) {
                        nameCompose.push(participant.resasNom)
                    }

                    if (participant.resasPrenom !== null) {
                        nameCompose.push(participant.resasPrenom)
                    }

                    if (participant.resasEmail !== null) {
                        nameCompose.push(`(${participant.resasEmail})`)
                    }

                    if (participant.transpondeur !== null) {
                        nameCompose.push(`[${participant.plateNumber}]`)
                    }
                    name = nameCompose.join(' ')
                }
                 return(Object.assign({name}, participant))
            })

            dispatch(Object.assign({}, {type: PARTICIPANT_LIST_SUCCESS}, {participants}));
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}

export function loadParticipant(id) {
    return (dispatch) => {
        return dispatch(loadResa(id)).then( data => {
            if (data.clients !== null) {
                return dispatch(loadDocuments(id, data.clients))
            }
        })
    }
}

export function loadResa(id) {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        // let url = `${process.env.REACT_APP_API_BASEURL}${id}`
        // if (store.currentTraining.currentDay !== null) {
        //     url = `${url}?resasOpt.bookedDate[after]=${store.currentTraining.currentDay}&resasOpt.bookedDate[before]=${store.currentTraining.currentDay}`
        // }
        return fetch(`${process.env.REACT_APP_API_BASEURL}${id}`, {
            method: 'GET',
            withCredentials: true,
            headers
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            if (store.currentTraining.currentDay !== null) {
                data.resasOpt = data.resasOpt.filter( (resaOpt) => resaOpt.bookedDate === store.currentTraining.currentDay)
            }
            dispatch(Object.assign({}, {type: PARTICIPANT_SUCCESS}, {data}))
            return data
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}

export function loadDocuments(resaId, participantId) {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        return fetch(`${process.env.REACT_APP_API_URL}/clients_docs?resa=${resaId}&client=${participantId}`, {
            method: 'GET',
            withCredentials: true,
            headers
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            const docs = data["hydra:member"]
            dispatch(Object.assign({}, {type: CLIENT_DOC_SUCCESS}, {docs}));
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}

export function reloadParticipant() {
    return (dispatch, getState) => {
        const store = getState()
        dispatch(loadParticipant(store.currentParticipant["@id"]))
    }
}

export function updateParticipant(resa, history) {
    return (dispatch, getState) => {
        const store = getState()

        delete(resa.resasOpt)
        delete(resa.resasDoc)
        delete(resa.resasPayments)

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        return fetch(`${process.env.REACT_APP_API_URL}/resas/${resa.resasId}`, {
            method: 'PUT',
            withCredentials: true,
            headers,
            body: JSON.stringify(resa)
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            batch(() => {
                dispatch(loadParticipantsList())
                dispatch(Object.assign({}, {type: PARTICIPANT_SUCCESS}, {data}))
                if (history) {
                    history.push('/dashboard')
                }
            })
            // dispatch(Object.assign({}, {type: PARTICIPANT_SUCCESS}, {data}));
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}

export function createParticipant(resa, history) {
    return (dispatch, getState) => {
        const store = getState()

        delete(resa.resasOpt)
        delete(resa.resasDoc)
        delete(resa.resasPayments)

        resa.articles = store.currentTraining["@id"]

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        return fetch(`${process.env.REACT_APP_API_URL}/resas`, {
            method: 'POST',
            withCredentials: true,
            headers,
            body: JSON.stringify(resa)
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            batch(() => {
                dispatch(loadParticipantsList())
                // dispatch({type: MODE_UPDATE, mode: MODE_EDIT})
                dispatch(changeMode(MODE_EDIT))
                dispatch(Object.assign({}, {type: PARTICIPANT_SUCCESS}, {data}))
                if (history) {
                    history.push('/dashboard')
                }
            })
            // dispatch(Object.assign({}, {type: PARTICIPANT_SUCCESS}, {data}));
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}

export function resetParticipant() {
    return (dispatch, getState) => {
        return new Promise(function (resolve, reject) {
            dispatch({type: PARTICIPANT_RESET})
            resolve()
        })

    }
}

export function resetResa() {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/merge-patch+json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})

        return fetch(`${process.env.REACT_APP_API_BASEURL}${store.currentParticipant["@id"]}/reset`, {
            method: 'PATCH',
            withCredentials: true,
            headers,
            body: JSON.stringify({})
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            dispatch(Object.assign({}, {type: PARTICIPANT_SUCCESS}, {data}))
            return data
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}
