import {createTheme} from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'

export const theme = createTheme({
    palette: {
        background: {
            default: grey[200]
        },
        warning: {
            light: "#7986cb",
            main: "#3f51b5",
            dark: "#303f9f",
            contrastText: "#fff"
        }
    }
});

export const useStyles = {
    '@global': {
        '.loginLogoBlack': {
            maxWidth: '200px',
            marginBottom: '2rem'
        },
        '.loginPaper': {
            padding: '3rem',
            textAlign: 'center'
        },
        '.formPaper': {
            padding: '2rem'
        },
        '.flexCenterXYFull': {
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        '.flexCenterXY': {
            display: "flex"
        },
        '.flexCenterXY > *': {
            margin: "auto"
        },
        '.ajaxLoaderContainer': {
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            zIndex: '1600',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: 'rgba(255,255,255,0.5)'
        },
        '.mainContainer': {
            display: "flex",
            height: "100vh",
            flexDirection: "column"
        },
        '.mainArea': {
            flex: "1",
            overflowY: "auto"
        },
        '.mt2': {
            marginTop: '16px'
        },
        '.mt4': {
            marginTop: '32px'
        },
        '.mb2': {
            marginBottom: '16px'
        },
        '.txtcenter': {
            textAlign: 'center'
        },
        '.my2' : {
            marginTop: '20px',
            marginBottom: '20px'
        },
        '.fullScreenCamera': {
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            zIndex: '1500'
        },
        '.message-success' : {
            padding: '20px',
            marginBottom: '16px',
            border: '1px solid #c3e6cb',
            backgroundColor: '#d4edda',
            color: '#155724',
            borderRadius: '5px'
        },
        '.message-warning' : {
            padding: '20px',
            marginBottom: '16px',
            border: '1px solid #ffeeba',
            backgroundColor: '#fff3cd',
            color: '#856404',
            borderRadius: '5px'
        },
        '.message-info' : {
            padding: '20px',
            marginBottom: '16px',
            border: '1px solid b8daff',
            backgroundColor: '#cce5ff',
            color: '#004085',
            borderRadius: '5px'
        },
        '.pre-wrap': {
            whiteSpace: 'pre-wrap'
        }
    },
    logoBlack: {
        maxWidth: '200px',
        marginBottom: '2rem'
    },
    logoToolbar: {
        maxWidth: '100px',
        marginRight: theme.spacing(2)
    },
    iconsToolbar: {
        flexGrow: 1,
        textAlign: 'right'
    },
    mt2: {
        marginTop: '16px'
    },
    toolbarStageTitle: {
        flexGrow: 1
    },
    dashboardButton: {
        minHeight: '15vh',
        color: '#ffffff',
        backgroundColor: grey[600],
        '&:hover': {
            backgroundColor: grey[400]
        }
    },
    dashboardWarningIcon: {
        marginRight: '10px'
    },
    dashboardWarningButton: {
        backgroundColor: orange[600],
        color: '#ffffff',
        '&:hover': {
            backgroundColor: orange[200]
        }
    },
    dashboardDangerButton: {
        backgroundColor: red[900],
        color: '#ffffff',
        '&:hover': {
            backgroundColor: red[500]
        }
    }
};

