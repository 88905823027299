import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

class AjaxLoader extends React.Component{
    render() {
        return(
            <div className="ajaxLoaderContainer">
                <CircularProgress color="secondary" />
            </div>
        )
    }
}

export default AjaxLoader