import React from 'react'
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import PartialLicence from "./partials/PartialLicence";
import PartialOptions from "./partials/PartialOptions";
import Grid from "@material-ui/core/Grid";
import NavigateNext from "@material-ui/icons/NavigateNext";
import Attachment from "@material-ui/icons/Attachment";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import PartialDocuments from "./partials/PartialDocuments";

class Licence extends React.Component{
    constructor(props) {
        super(props)

        this.state = {
            docDialog: false,
            currentParticipant: this.props.currentParticipant
        }
    }

    handleOpenDialogDoc() {
        this.setState({
            docDialog: true
        })
    }


    handleCloseDialogDoc() {
        this.setState({
            docDialog: false
        })
    }

    handleChangeInsurance(resa) {
        this.setState({
            currentParticipant : Object.assign({}, this.state.currentParticipant, resa)
        })
    }

    handleSubmit() {
        this.props.handleSubmitResa(this.state.currentParticipant)
    }

    render() {
        return(
            <div className="mainArea flexCenterXY">
                <Container component="div" maxWidth="md">
                    <Paper className="formPaper">
                        <PartialLicence
                            currentParticipant={this.state.currentParticipant}
                            handleChange={this.handleChangeInsurance.bind(this)}
                        />

                        <div className={'mt2'}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={this.handleSubmit.bind(this)}
                            >
                                Enregistrer
                            </Button>
                        </div>
                    </Paper>
                    <Paper className="formPaper mt2">
                        <PartialOptions
                            currentTraining={this.props.currentTraining}
                            currentParticipant={this.props.currentParticipant}
                            handleSubmit={this.props.handleSubmitOption}
                            handleRemove={this.props.handleRemoveOption}
                        />
                    </Paper>
                    <Paper className="formPaper mt2">
                        <Grid container justifyContent="center" spacing={1}>
                            <Grid item  xs={12} sm={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={this.handleOpenDialogDoc.bind(this)}
                                >
                                    Documents
                                    <Attachment/>
                                </Button>
                            </Grid>
                            <Grid item  xs={12} sm={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() => {this.props.history.push('/notes')}}
                                >
                                    Valider la réservation
                                    <NavigateNext/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Dialog
                        open={this.state.docDialog}
                        fullScreen={true}
                        onClose={this.handleCloseDialogDoc.bind(this)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogActions>
                            <Button onClick={this.handleCloseDialogDoc.bind(this)} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>

                        <PartialDocuments
                            currentParticipant={this.props.currentParticipant}
                            clientDocs={this.props.clientDocs}
                            handleSubmit={this.props.handleSubmitDocument}
                            handleRemoveDoc={this.props.handleRemoveDocument}
                            handleSubmitResa={this.props.handleSubmitResa}
                        />

                    </Dialog>
                </Container>
            </div>
        )
    }
}

export default Licence