import React from 'react'
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {loadParticipantsList, loadParticipant} from '../actions/participants'
import {changeMode} from '../actions/mode'
import {connect} from "react-redux";
import indigo from '@material-ui/core/colors/indigo';
import searchFilter from "../tools/searchFilter";

import ExtendedSelect from "../components/ExtendedSelect";
import _ from 'lodash'
import {MODE_CREATE, MODE_EDIT} from "../constants/actions";

let groupedOptions = []


const filterOption = (option, string) => {
    // default search
    if (searchFilter(string, option)) {
        return true;
    }

    // check if a group as the filter string as label
    const groupOptions = groupedOptions.filter(group =>
        group.options && searchFilter(string, group)
    );

    if (groupOptions) {
        for (const groupOption of groupOptions) {
            // Check if current option is in group
            const currentOption = groupOption.options.find(opt => opt.value === option.value);
            if (currentOption) {
                return true;
            }
        }
    }
    return false;
};

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        fontWeight: state.isSelected ? 500 : 400,
        color: state.data.transpondeur ? indigo[900] : state.isSelected ? '#ffffff' : '#000000',
        backgroundColor: state.isDisabled
            ? null
            : state.isSelected
                ? "#3f50b5"
                : state.isFocused
                    ? "rgba(63,81,191,0.2)"
                    : null,
    })
}

class Search extends React.Component {
    constructor(props) {
        super(props)

        const currentParticipant = this.props.currentParticipant["@id"]
        // const participantItem = this.props.participantsList.find(function (participant) {
        //     if (participant["@id"] === currentParticipant) {
        //         return true
        //     }
        // })
        const participantItem = this.props.participantsList.find( participant =>  participant["@id"] === currentParticipant )

        this.state = {
            participantId: participantItem ? {value: participantItem["@id"], label: participantItem.name} : ""
        }
    }

    componentDidMount() {
        this.props.loadParticipantsList()
    }

    handleChange(participantId) {
        this.setState({
            participantId
        })
    }

    handleClick() {
        if (this.state.participantId.value) {
            this.props.changeMode(MODE_EDIT).then( () => {
                this.props.loadParticipant(this.state.participantId.value).then( () => {
                    this.props.history.push('/contact')
                })
            })

            // console.log(test)
        }
    }

    handleModeCreation() {
        this.props.changeMode(MODE_CREATE).then(() =>  {
            this.props.history.push('/contact')
        })
    }

    formatgrouped() {
        const grouped = _.groupBy(this.props.participantsList, function (participant) {
            return participant.acheteurName
        })

        const formatted = []
        for (let [key, value] of Object.entries(grouped)) {
            // console.log(`${key}: ${value}`);
            if (value.length === 1) {
                formatted.push({
                    label: value[0].name,
                    value: value[0]["@id"],
                    transpondeur: value[0].transpondeur !== null,
                    numTranspondeur: value[0].plateNumber
                })
            } else {
                formatted.push({
                    label: key,
                    options: value.map(participant => ({
                        label: participant.name,
                        value: participant["@id"],
                        transpondeur: participant.transpondeur !== null,
                        numTranspondeur: participant.plateNumber
                    }))
                },)
            }
        }

        groupedOptions = formatted
        return formatted
    }

    render () {
        return (
            <div className="mainArea flexCenterXY">
                <Container component="div" maxWidth="sm">
                    <Paper className="loginPaper">
                        <Typography component="h1" variant="h5" gutterBottom>
                            Sélectionnez le participant
                        </Typography>
                        <ExtendedSelect
                            inputId={"react-select-single"}
                            label={"Liste des participants"}
                            placeholder={"Choisissez un participant"}
                            options={this.formatgrouped()}
                            value={this.state.participantId}
                            onChange={this.handleChange.bind(this)}
                            filterOption={filterOption}
                            styles={customStyles}
                            noOptionsMessage={() => "Aucun participant trouvé"}
                        />
                        <div className="mt2">
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={this.handleClick.bind(this)}
                            >
                                Sélectionner
                            </Button>
                        </div>
                        <div className={"mt2"}>
                            <Typography component="h6" gutterBottom>
                                Ou
                            </Typography>
                        </div>
                        <div className="mt2">
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={this.handleModeCreation.bind(this)}
                            >
                                Créer une réservation
                            </Button>
                        </div>
                        <div className={"mt2"}>
                            { !this.props.currentTraining.multiDays
                                ? <Typography component="h6" gutterBottom>
                                    ({ this.props.currentTraining.trainingPrices[0].totalSpots - this.props.currentTraining.trainingPrices[0].reservedSpots } places restantes)
                                </Typography>
                                : <>
                                    { this.props.currentTraining.trainingPrices.map( (trainingPrice, idx) =>
                                        <Typography component="h6" gutterBottom key={idx}>
                                            {trainingPrice.name} : { trainingPrice.totalSpots - trainingPrice.reservedSpots } places restantes
                                        </Typography>
                                    )}
                                </>
                            }

                        </div>
                    </Paper>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {participantsList, currentParticipant, mode, currentTraining} = state;
    return {
        participantsList,
        currentParticipant,
        mode,
        currentTraining
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadParticipantsList: () => dispatch(loadParticipantsList()),
        loadParticipant: (id) => dispatch(loadParticipant(id)),
        changeMode: (mode) => dispatch(changeMode(mode))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
