import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage/session'

import auth from './auth'
import config from './config'
import currentTraining from './currentTraining'
import trainingList from './trainingList'
import participantsList from './participantsList'
import currentParticipant from './currentParticipant'
import clientDocs from './clientDocs'
import dialog from './dialog'
import mode from "./mode";
import {LOGOUT} from "../constants/actions";

const AppReducer = combineReducers({
    auth,
    config,
    currentTraining,
    trainingList,
    participantsList,
    currentParticipant,
    clientDocs,
    dialog,
    mode
})

export default (state, action) => {
    if (action.type === LOGOUT) {
        storage.removeItem('persist:root')
        state = undefined
    }

    return AppReducer(state, action)
}