import {AJAX_FAILURE, AJAX_REQUEST, AJAX_SUCCESS} from "../constants/actions";
import {reloadParticipant} from "./participants";
import {dataURItoBlob, getFileName} from "../tools/files";



export function uploadDocument(document) {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        const formData  = new FormData();
        formData.append('resa', store.currentParticipant["@id"])
        formData.append('client', store.currentParticipant.clients)
        formData.append('description', document.description)
        if (document.expiration !== null) {
            formData.append('expiration', document.expiration)
        }

        const blobFile = dataURItoBlob(document.file)
        formData.append('file', blobFile, getFileName(`${document.resa} ${document.description}`, blobFile.type, document.key))

        dispatch({type: AJAX_REQUEST})
        fetch(`${process.env.REACT_APP_API_URL}/clients_docs`, {
            method: 'POST',
            withCredentials: true,
            headers,
            body: formData
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            dispatch(reloadParticipant())
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}

export function deleteDocument(id) {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        fetch(`${process.env.REACT_APP_API_BASEURL}${id}`, {
            method: 'DELETE',
            withCredentials: true,
            headers
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                dispatch(reloadParticipant())
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}