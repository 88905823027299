import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import {useStyles} from "../theme";
import {withStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

class FooterBar extends React.Component{
    render() {
        const {classes} = this.props
        return(
            <AppBar position="static">
                <Toolbar>
                    <Grid container justifyContent="center" spacing={2}>
                        {this.props.currentTraining.id &&
                        <Grid item  xs={12} sm={6}>
                            <Typography className={classes.toolbarStageTitle} variant="h6" align="center">
                                {this.props.currentTraining.designation}
                                { this.props.currentTraining.currentDay &&
                                    <> - {moment(this.props.currentTraining.currentDay).format("DD/MM/Y")}</>
                                }
                            </Typography>
                        </Grid>
                        }
                        {this.props.currentParticipant.resasId &&
                        <Grid item  xs={12} sm={6}>
                            <Typography className={classes.toolbarStageTitle} variant="h6" align="center">
                                {`${this.props.currentParticipant.resasNom} ${this.props.currentParticipant.resasPrenom}`}
                            </Typography>
                        </Grid>
                        }
                    </Grid>
                </Toolbar>
            </AppBar>
        )
    }
}

export default withStyles(useStyles)(FooterBar)