import {AJAX_FAILURE, AJAX_REQUEST, AJAX_SUCCESS} from "../constants/actions"
import {reloadParticipant} from "./participants";

export function addPayment(payment) {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        if (!payment.resa) {
            payment.resa = store.currentParticipant['@id']
        }

        dispatch({type: AJAX_REQUEST})

        return fetch(`${process.env.REACT_APP_API_URL}/resas_payments`, {
            method: "POST",
            withCredentials: true,
            headers,
            body: JSON.stringify(payment)
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            dispatch(reloadParticipant())
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}

export function removePayment(id) {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        return fetch(`${process.env.REACT_APP_API_BASEURL}${id}`, {
            method: 'DELETE',
            withCredentials: true,
            headers
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                dispatch(reloadParticipant())
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}

