import {MODE_CREATE, MODE_UPDATE, PARTICIPANT_RESET} from "../constants/actions";
import {batch} from "react-redux";


export function changeMode(mode) {
    return dispatch => {
        return new Promise(function (resolve, reject) {
            if (mode === MODE_CREATE) {
                batch( () => {
                    dispatch({type: PARTICIPANT_RESET})
                    dispatch({
                        type: MODE_UPDATE,
                        mode
                    })
                })
            } else {
                dispatch({
                    type: MODE_UPDATE,
                    mode
                })
            }
            resolve()
        })

    }
}