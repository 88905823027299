export function split_groups(participants, nbGroups) {
    nbGroups = parseInt(nbGroups, 10)
    const groups = []
    for (let i = 0; i < nbGroups; i++) {
        groups.push([])
    }

    const groupPriority = []
    const noPriority = []

    participants.sort(function (a,b) {
        const aName = a.resasNom || ''
        const bName = b.resasNom || ''
        return aName.localeCompare(bName)
    })

    participants.forEach(function (participant) {
        if (participant.groupPriority !== null && participant.groupPriority <= nbGroups) {
            groupPriority.push(participant)
        } else {
            noPriority.push(participant)
        }
    })

    noPriority.sort(function(a, b) {
        const aTime = a.absoluteTimeRef === 0 ? 999999999 : a.absoluteTimeRef
        const bTime = b.absoluteTimeRef === 0 ? 999999999 : b.absoluteTimeRef
        // return (aTime - bTime) * -1;
        return aTime - bTime;
    });

    const nbPerGroup = Math.ceil(participants.length / nbGroups);
    const lissage = (nbPerGroup * nbGroups) - participants.length;

    groupPriority.forEach(participant => {
        groups[participant.groupPriority - 1].push(participant)
    })
    
    let currentGroup = 0
    noPriority.forEach(function (participant) {
        const nbPerCurrentGroup = currentGroup >= nbGroups - lissage ? nbPerGroup - 1 : nbPerGroup;
        if (groups[currentGroup].length >= nbPerCurrentGroup) {
            currentGroup++
        }
        groups[currentGroup].push(participant);
    })

    // console.log(groups)
    return groups
}