import React from 'react'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import logo from "../assets/images/logo.svg"
import {useStyles} from "../theme";
import {withStyles} from '@material-ui/styles'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import Search from '@material-ui/icons/Search'
import List from '@material-ui/icons/List'
import ViewModule from "@material-ui/icons/ViewModule"
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { logout } from '../actions/auth'
import {compose} from "redux";
import {connect} from "react-redux";
import {MODE_CREATE} from "../constants/actions";
import {loadParticipantsList} from "../actions/participants";

class MenuBar extends React.Component {

    handleClickLogout() {
        this.props.logout()
    }

    handleClickTraining() {
        this.props.history.push('/training')
    }

    handleClickSearch() {
        this.props.history.push('/search')
    }

    handleClickDashboard() {
        this.props.history.push('/dashboard')
    }

    handleClickGroup() {
        this.props.loadParticipantsList().then(() => {
            this.props.history.push('/groupes')
        })
    }

    render() {
        const {classes} = this.props
        return (
            <AppBar position="static">
                <Toolbar>
                    <img src={logo} className={classes.logoToolbar} alt="BMC" />
                    <div className={classes.iconsToolbar}>
                        <Button color="inherit" onClick={this.handleClickTraining.bind(this)}><List fontSize="large"/></Button>
                        {this.props.currentTraining.id &&
                        <Button color="inherit" onClick={this.handleClickSearch.bind(this)}>
                            <Search fontSize="large"/>
                        </Button>
                        }
                        {(this.props.currentParticipant.resasId || this.props.mode === MODE_CREATE) &&
                        <Button color="inherit" onClick={this.handleClickDashboard.bind(this)}>
                            <ViewModule fontSize="large"/>
                        </Button>
                        }
                        {this.props.currentTraining.id &&
                        <Button color="inherit" onClick={this.handleClickGroup.bind(this)}>
                            <FormatListNumberedIcon fontSize="large"/>
                        </Button>
                        }
                        <Button color="inherit" onClick={this.handleClickLogout.bind(this)}><PowerSettingsNew fontSize="large" /></Button>
                    </div>
                </Toolbar>

            </AppBar>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout()),
        loadParticipantsList: () => dispatch(loadParticipantsList())
    }
}

export default compose(withStyles(useStyles), connect(null, mapDispatchToProps))(MenuBar)
