import {
    AJAX_FAILURE,
    TRAINING_LIST_SUCCESS,
    CURRENT_TRAINING_SUCCESS,
    AJAX_REQUEST,
    AJAX_SUCCESS
} from "../constants/actions";
import moment from 'moment'

export function loadTraining(id, day=null) {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        return fetch(`${process.env.REACT_APP_API_BASEURL}${id}`, {
            method: 'GET',
            withCredentials: true,
            headers
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then(data => {
            dispatch(Object.assign({}, {type: CURRENT_TRAINING_SUCCESS}, {
                "@id": data["@id"],
                id: data.articlesId,
                ref: data.articlesRef,
                prix: data.articlesPrix,
                designation: data.articlesDesignation,
                dateStart: data.articlesStageStart,
                dateEnd: data.articlesStageEnd,
                places: data.groupesPlaces,
                resas: data.groupesResas,
                commentaire: data.articlesStageCommentaires,
                multiDays: data.multiDays,
                options: data.stagesOpt,
                trainingPrices: data.trainingPrices,
                currentDay: day
            }));

        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });

    }
}

export function reloadTraining() {
    return (dispatch, getState) => {
        const store = getState()
        dispatch(loadTraining(store.currentTraining["@id"], store.currentTraining.currentDay))
    }
}

export function loadTrainingList() {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        fetch(`${process.env.REACT_APP_API_URL}/articles?articlesType=STAGE&articlesStageEnd[after]=${moment().format('YYYY-MM-DD')}&order[articlesStageEnd]=asc`, {
            method: 'GET',
            withCredentials: true,
            headers
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            // const trainings = data.map( function (training) {
            //     return({id: training.articlesId, designation: training.articlesDesignation})
            // })

            dispatch(Object.assign({}, {type: TRAINING_LIST_SUCCESS}, {trainings: data["hydra:member"]}));
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}