import jwtDecode from 'jwt-decode'
import {LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, AJAX_REQUEST, AJAX_SUCCESS} from "../constants/actions";

export function decodeToken(token) {
    const decoded = jwtDecode(token);
    return {
        token,
        username : decoded.username,
        roles : decoded.roles,
        id : decoded.id,
        exp : decoded.exp,
        iat : decoded.iat
    }
}

export function importToken(token) {
    return dispatch => {
        dispatch(Object.assign({}, {type: LOGIN_SUCCESS}, decodeToken(token)))
    }
}

export function login(username, password) {
    return (dispatch) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        dispatch({type: AJAX_REQUEST})
        fetch(`${process.env.REACT_APP_API_URL}/authentication_token`, {
            method: 'POST',
            withCredentials: false,
            headers,
            body: JSON.stringify({
                username,
                password
            })
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            // sessionStorage.setItem(TOKEN_NAME, data.token);
            const token = decodeToken(data.token)
            const isAllowed = token.roles.includes('ROLE_ADMIN') || token.roles.includes('ROLE_INSTRUCTOR')
            if (!isAllowed) {
                throw new Error('Vous n\'avez pas les droits suffisants pour accéder à cette application.')
            }
            dispatch(Object.assign({}, {type: LOGIN_SUCCESS}, decodeToken(data.token)));
        }).catch((error) => {
            dispatch(Object.assign({}, {type: LOGIN_FAILURE}, {message: error.message}))
        });
    }
}

export function logout() {
    return (dispatch) => {
        // sessionStorage.removeItem(TOKEN_NAME);
        dispatch(Object.assign({}, {type: LOGOUT}))
    }
}