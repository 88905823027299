import {AJAX_FAILURE, AJAX_REQUEST, AJAX_RESET, AJAX_SUCCESS} from "../constants/actions";


const initialState = {
    loading : false,
    activeRequests: 0,
    error: false,
    message: ''
}

export default function dialog(state = initialState, action) {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case AJAX_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                error: false,
                activeRequests: newState.activeRequests + 1,
                message: ''
            })

        case AJAX_SUCCESS:
            return Object.assign({}, state, {
                loading: state.activeRequests > 1,
                error: false,
                activeRequests: state.activeRequests > 1 ? state.activeRequests - 1 : 0,
                message: ''
            })

        case AJAX_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: true,
                message: action.message
            })

        case AJAX_RESET:
            return initialState

        default:
            return state
    }
}