import {CLIENT_DOC_SUCCESS, CLIENT_DOC_RESET} from "../constants/actions";

const initialState = []

export default function clientDocs(state = initialState, action) {
    switch (action.type) {
        case CLIENT_DOC_SUCCESS:
            return action.docs
        case CLIENT_DOC_RESET:
            return initialState
        default:
            return state
    }
}