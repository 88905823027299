import React from 'react'
import { Route, Redirect} from 'react-router-dom'
import {connect} from "react-redux";
import Home from './Home'


class PrivateArea extends React.Component{
    render() {
        return(
            <Route
                path="/"
                render={props =>
                    this.props.auth.isAuthenticated ? (
                        <Home {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: this.props.location }
                            }}
                        />
                    )
                }
            />
        )
    }
}

function mapStateToProps(state) {
    const { auth } = state;
    return {
        auth
    }
}

export default connect(mapStateToProps)(PrivateArea);
