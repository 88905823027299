import React from "react"
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import CachedIcon from '@material-ui/icons/Cached';
import Button from "@material-ui/core/Button";
class Transponders extends React.Component {
    render() {

        const participants = this.props.participantsList.filter((participant) => participant.plateNumber !== null && participant.transpondeur !== null)
        participants.sort(function (a, b) {
            return a.plateNumber - b.plateNumber
        })

        return (
            <div className="mainArea flexCenterXY">
                <Container component="div" maxWidth="md">
                    <Paper className="formPaper">
                        <Typography component="h1" variant="h5" gutterBottom>
                            Correspondance des transpondeurs
                            <Button color="secondary" onClick={this.props.loadParticipantsList}>
                                <CachedIcon />
                            </Button>
                        </Typography>

                        <Divider />

                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Nom</TableCell>
                                    <TableCell align="center">Prénom</TableCell>
                                    <TableCell align="center">Plaque</TableCell>
                                    <TableCell align="center">Transpondeur</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {participants.map( (participant) => (
                                    <TableRow key={participant['@id']}>
                                        <TableCell align="center">{participant.resasNom || '-'}</TableCell>
                                        <TableCell align="center">{participant.resasPrenom || '-'}</TableCell>
                                        <TableCell align="center">{participant.plateNumber}</TableCell>
                                        <TableCell align="center">{participant.transpondeur}</TableCell>
                                        {/*<TableCell align="right">{participant.absoluteTimeRef}</TableCell>*/}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Container>
            </div>
        )
    }
}

export default Transponders