import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import MenuBar from './MenuBar'
import {connect} from "react-redux";
import {loadConfig} from "../actions/config";
import Training from "./Training";
import Search from "./Search";
import DashBoardRoutes from "./DashBoardRoutes";
import FooterBar from "./FooterBar";
import {MODE_CREATE} from "../constants/actions";
import Groupes from "../components/Groupes";
import Transponders from "../components/Transponders";
import {loadParticipantsList} from "../actions/participants";

class Home extends React.Component {
    componentDidMount() {
        if (this.props.config.loaded === false) {
            this.props.loadConfig()
        }
    }

    render() {
        return (
            <div className="mainContainer">
                <MenuBar {...this.props}/>
                <Switch>
                    <Route exact path="/training" component={Training}/>
                    <Route
                        exact
                        path="/search"
                        render={props =>
                            this.props.currentTraining.id !== null ? (
                                <Search {...props} />
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: "/training",
                                        state: {from: this.props.location}
                                    }}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/groupes"
                        render={props =>
                            this.props.currentTraining.id !== null ? (
                                <Groupes  participantsList={this.props.participantsList} {...props} />
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: "/training",
                                        state: {from: this.props.location}
                                    }}
                                />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/transponders"
                        render={props =>
                            this.props.currentTraining.id !== null ? (
                                <Transponders  participantsList={this.props.participantsList} loadParticipantsList={this.props.loadParticipantsList} {...props} />
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: "/training",
                                        state: {from: this.props.location}
                                    }}
                                />
                            )
                        }
                    />
                    <Route
                        path="/"
                        render={props =>
                            this.props.currentParticipant.resasId !== null || this.props.mode === MODE_CREATE ? (
                                <DashBoardRoutes {...props} />
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: "/search",
                                        state: {from: this.props.location}
                                    }}
                                />
                            )
                        }
                    />
                </Switch>
                <FooterBar {...this.props}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {config, currentTraining, currentParticipant, mode, participantsList} = state;
    return {
        config,
        currentTraining,
        currentParticipant,
        mode,
        participantsList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadConfig: () => dispatch(loadConfig()),
        loadParticipantsList: () => dispatch(loadParticipantsList())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)