import React from "react"
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {MODE_CREATE} from "../constants/actions";
import AsyncSelect from 'react-select/async';
import Divider from "@material-ui/core/Divider";
import {convertCsToObject, convertObjectToCs} from "../tools/times"
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import PartialLicence from "./partials/PartialLicence";
import PartialOptions from "./partials/PartialOptions";
import Attachment from "@material-ui/icons/Attachment";
import DialogActions from "@material-ui/core/DialogActions";
import PartialDocuments from "./partials/PartialDocuments";
import Dialog from "@material-ui/core/Dialog";

class ContactInformations extends React.Component{
    constructor(props) {
        super(props)

        const {resasNaissance, trainingPrice,  ...participant} = this.props.currentParticipant

        this.state = {
            resa: Object.assign({
                trainingPrice: trainingPrice || this.props.currentTraining.trainingPrices[0]["@id"],
                resasNaissance: moment(resasNaissance).format('YYYY-MM-DD')
            }, participant),
            timeref: Object.assign({}, {resasCircuitsRef: participant.resasCircuitsRef || ""}, convertCsToObject(participant.resasTimeRef)),
            selectedClient: null,
            docDialog: false,
            updated: false
        }
    }

    handleOpenDialogDoc() {
        this.setState({
            docDialog: true
        })
    }


    handleCloseDialogDoc() {
        this.setState({
            docDialog: false
        })
    }

    handleChange(section, key, e) {
        this.setState({
            [section] : Object.assign({}, this.state[section], {[key]: e.target.value}),
            updated: true
        })
    }

    handleChangeCheckbox(key, e) {
        this.setState({
            resa : Object.assign({}, this.state.resa, {[key]: e.target.checked ? "Y" : "N"}),
            updated: true
        })
    }

    handleChangeRelation(key, e) {
        this.setState({
            resa : Object.assign({}, this.state.resa, {[key]: e.target.value === '' ? null : e.target.value}),
            updated: true
        })
    }

    checkValidDischarge() {
        const dischargeDate = moment(this.state.resa.discharge)
        return dischargeDate.isValid() && dischargeDate.isAfter(moment());
    }

    handleChangeDischarge (e) {
        this.setState({
            resa : Object.assign({}, this.state.resa, {discharge: e.target.checked ? moment(new Date(new Date().getFullYear(), 11, 31)).format('YYYY-MM-DD') : null}),
            updated: true
        })
    }

    handleChangeInsurance(resa) {
        this.setState({
            resa : Object.assign({}, this.state.resa, resa),
            updated: true
        })
    }


    handleSubmit(route, e) {
        if (this.state.updated === true) {
            if (this.state.resa.resasNom && this.state.resa.resasPrenom && this.state.resa.resasEmail) {
                const resa = this.state.resa
                if (!moment(resa.resasNaissance).isValid()) {
                    resa.resasNaissance = null
                }

                if (this.state.timeref.resasCircuitsRef && this.state.timeref.min >=0 && this.state.timeref.sec >= 0 && this.state.timeref.sec < 60 && this.state.timeref.cs >=0 && this.state.timeref.cs < 100) {
                    resa.resasCircuitsRef =  this.state.timeref.resasCircuitsRef
                    resa.resasTimeRef = convertObjectToCs(this.state.timeref)
                } else {
                    resa.resasCircuitsRef = null
                    resa.resasTimeRef = null
                }

                resa.groupPriority = parseInt(this.state.resa.groupPriority, 10) > 0 ? parseInt(this.state.resa.groupPriority, 10) : null
                console.log(this.props)
                this.props.handleSubmit(resa).then(() => {
                    this.setState({
                        updated: false
                    }, () => {
                        this.props.history.push(route)
                    })
                })
            }
        } else {
            this.props.history.push(route)
        }
    }

    promiseOptions(inputValue) {
        if (inputValue.length >= 3) {
            return this.props.searchClients(inputValue).then(data => {
                return(data['hydra:member'].map( function(client) {
                    return {
                        value: client['@id'],
                        label: `${client.clientsNom} ${client.clientsPrenom} (${client.clientsEmail})`
                    }
                } ))
            })
        }
    }

    handleClient(e) {
        this.setState({
            selectedClient: e
        })
    }

    handleFillResa() {
        this.props.loadClient(this.state.selectedClient.value).then( (data) => {
            const expiredInsurance = data.assuranceExpiration === null ? false : moment(data.assuranceExpiration).isBefore(moment())
            const resa = Object.assign({}, this.state.resa, {
                resasNom: data.clientsNom,
                resasPrenom: data.clientsPrenom,
                resasEmail: data.clientsEmail,
                resasMobile: data.clientsMobile,
                resasNaissance: moment(data.clientsNaissance).format('YYYY-MM-DD'),
                resasTimeRef: data.clientsTimeRef,
                resasCircuitsRef: data.clientsCircuitsRef,
                clients: data["@id"],
                // acheteur: this.state.acheteur || data["@id"],
                mailing: data.mailing,
                assurance: !expiredInsurance ? data.assurance : null,
                assurancePolice: !expiredInsurance ? data.assurancePolice : null,
                assuranceCompagnie: !expiredInsurance ? data.assuranceCompagnie : null,
                assuranceExpiration: !expiredInsurance ? data.assuranceExpiration : null,
                discharge: data.discharge
            })

            const timeref = Object.assign({}, {resasCircuitsRef: data.clientsCircuitsRef || ""}, convertCsToObject(data.clientsTimeRef))

            this.setState({
                resa,
                timeref,
                updated: true
            })
        })
    }

    render() {
        const groupsList = [
            {
                value: 1,
                label: 'A'
            },
            {
                value: 2,
                label: 'B'
            },
            {
                value: 3,
                label: 'C'
            },
            {
                value: 4,
                label: 'D'
            },
            {
                value: 5,
                label: 'E'
            },
            {
                value: 6,
                label: 'F'
            },
            {
                value: 7,
                label: 'G'
            },
            {
                value: 8,
                label: 'H'
            }
        ]

        return(
            <div className="mainArea flexCenterXY">
                <Container component="div" maxWidth="md">
                    {this.props.mode === MODE_CREATE && this.props.currentTraining.multiDays &&
                        <Paper className="formPaper mb2">
                            <Typography component="h1" variant="h5" gutterBottom>
                                Période
                            </Typography>
                            <FormControl fullWidth>
                                <NativeSelect
                                    value={this.state.resa.trainingPrice ? this.state.resa.trainingPrice['@id'] : this.props.currentTraining.trainingPrices[0]["@id"]}
                                    onChange={this.handleChangeRelation.bind(this, 'trainingPrice')}
                                    input={<Input name="trainingPrice" id="trainingprice-native-helper" />}
                                >
                                    {this.props.currentTraining.trainingPrices.map(function(item) {
                                        return (
                                            <option key={item.id} value={item['@id']}>{item.name}</option>
                                        )
                                    })}
                                </NativeSelect>
                            </FormControl>
                        </Paper>
                    }
                    {this.props.mode !== MODE_CREATE && this.props.currentTraining.multiDays &&
                        <Paper className="formPaper mb2">
                            <Typography component="h6" variant="h6" gutterBottom>
                                Réservation :
                                {this.props.currentParticipant.trainingStart !== this.props.currentParticipant.trainingEnd
                                    ? <> du {moment(this.props.currentParticipant.trainingStart).format('DD/MM/YYYY')} au {moment(this.props.currentParticipant.trainingEnd).format('DD/MM/YYYY')}</>
                                    : <> journée du {moment(this.props.currentParticipant.trainingStart).format('DD/MM/YYYY')}</>
                                }
                            </Typography>
                        </Paper>
                    }
                    <Paper className="formPaper">
                        <Typography component="h1" variant="h5" gutterBottom>
                            Coordonnées client
                        </Typography>
                        { (this.props.mode === MODE_CREATE || this.props.currentParticipant.resasNom === null) &&
                            <div>
                                <Typography component="h6" gutterBottom>
                                    Préremplir la fiche à partir d'un client existant :
                                </Typography>
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={this.promiseOptions.bind(this)}
                                    placeholder="Taper au moins 3 caractères pour lancer la recherche (nom ou email)..."
                                    onChange={this.handleClient.bind(this)}
                                    isClearable={true}
                                />
                                { this.state.selectedClient &&
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    className={"mt2"}
                                    onClick={this.handleFillResa.bind(this)}
                                >
                                    Pré-remplir la fiche
                                </Button>
                                }
                                <Divider className={"mt2"}/>
                            </div>
                        }
                        <TextField
                            id="resas-nom"
                            label="Nom"
                            error={!this.state.resa.resasNom}
                            value={this.state.resa.resasNom || ''}
                            required
                            onChange={this.handleChange.bind(this, 'resa', 'resasNom')}
                            margin="normal"
                            fullWidth
                        />
                        <TextField
                            id="resas-prenom"
                            label="Prénom"
                            error={!this.state.resa.resasPrenom}
                            value={this.state.resa.resasPrenom || ''}
                            required
                            onChange={this.handleChange.bind(this, 'resa', 'resasPrenom')}
                            margin="normal"
                            fullWidth
                        />
                        <TextField
                            id="resas-email"
                            label="Email"
                            error={!this.state.resa.resasEmail}
                            value={this.state.resa.resasEmail || ''}
                            required
                            onChange={this.handleChange.bind(this, 'resa', 'resasEmail')}
                            margin="normal"
                            fullWidth
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.resa.mailing === "Y"}
                                    onChange={this.handleChangeCheckbox.bind(this, 'mailing')}
                                    value="licence"
                                    color="primary"
                                />
                            }
                            label="Recevoir la newsletter"
                        />
                        <TextField
                            id="resas-mobile"
                            label="Téléphone mobile"
                            value={this.state.resa.resasMobile || ''}
                            onChange={this.handleChange.bind(this, 'resa', 'resasMobile')}
                            margin="normal"
                            fullWidth
                        />


                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.checkValidDischarge()}
                                    onChange={this.handleChangeDischarge.bind(this)}
                                    value="discharge"
                                    color="primary"
                                />
                            }
                            label="Décharge annuelle signée"
                        />
                    </Paper>
                    <Paper className="formPaper mt2">
                        <Typography component="h1" variant="h5" className={'mt2'} gutterBottom>
                            Temps de référence
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="trackref-native-helper">Circuit</InputLabel>
                            <NativeSelect
                                value={this.state.timeref.resasCircuitsRef}
                                onChange={this.handleChange.bind(this, 'timeref', 'resasCircuitsRef')}
                                input={<Input name="trackref" id="trackref-native-helper" />}
                            >
                                <option value="" />
                                {this.props.tracks.map(function(item) {
                                    return (
                                        <option key={item.circuitsId} value={item['@id']}>{item.circuitsName}</option>
                                    )
                                })}
                            </NativeSelect>
                        </FormControl>
                        <FormGroup row>
                            <TextField
                                id="timeref-min"
                                label="Minutes"
                                value={this.state.timeref.min}
                                required
                                onChange={this.handleChange.bind(this, 'timeref', 'min')}
                                margin="normal"
                                type="number"
                            />
                            <TextField
                                id="timeref-sec"
                                label="Secondes"
                                value={this.state.timeref.sec}
                                required
                                onChange={this.handleChange.bind(this, 'timeref', 'sec')}
                                margin="normal"
                                type="number"
                                error={this.state.timeref.sec > 59}
                                inputProps={{'max': 59}}
                            />
                            <TextField
                                id="timeref-cs"
                                label="Centièmes"
                                value={this.state.timeref.cs}
                                required
                                onChange={this.handleChange.bind(this, 'timeref', 'cs')}
                                margin="normal"
                                type="number"
                                error={this.state.timeref.cs > 99}
                                inputProps={{'max': 99}}
                            />
                        </FormGroup>
                    </Paper>
                    <Paper className="formPaper mt2">
                        <Typography component="h1" variant="h5" className={'mt2'} gutterBottom>
                            Choix du groupe
                        </Typography>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="group-priority-native-helper">Préférence de groupe</InputLabel>
                            <NativeSelect
                                value={this.state.resa.groupPriority || ''}
                                onChange={this.handleChange.bind(this, 'resa', 'groupPriority')}
                                input={<Input name="group-priority" id="group-priority-native-helper" />}
                            >
                                <option value="" />
                                {groupsList.map(function(item, idx) {
                                    return (
                                        <option key={idx} value={item.value}>{item.label}</option>
                                    )
                                })}
                            </NativeSelect>
                        </FormControl>
                    </Paper>
                    <Paper className="formPaper mt2">
                        <PartialLicence
                            currentParticipant={this.state.resa}
                            handleChange={this.handleChangeInsurance.bind(this)}
                        />
                    </Paper>

                    <Paper className="formPaper mt2">
                        <PartialOptions
                            currentTraining={this.props.currentTraining}
                            currentParticipant={this.props.currentParticipant}
                            handleSubmit={this.props.handleSubmitOption}
                            handleRemove={this.props.handleRemoveOption}
                        />
                    </Paper>


                    <div className={'mt2'}>
                        <Grid container justifyContent="center" spacing={1}>
                            <Grid item  xs={12} sm={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={this.handleSubmit.bind(this, '/notes')}
                                >
                                    Valider la Réservation
                                </Button>
                            </Grid>

                            <Grid item  xs={12} sm={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={this.handleOpenDialogDoc.bind(this)}
                                >
                                    Documents
                                    <Attachment/>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <Dialog
                        open={this.state.docDialog}
                        fullScreen={true}
                        onClose={this.handleCloseDialogDoc.bind(this)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogActions>
                            <Button onClick={this.handleCloseDialogDoc.bind(this)} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>

                        <PartialDocuments
                            currentParticipant={this.props.currentParticipant}
                            clientDocs={this.props.clientDocs}
                            handleSubmit={this.props.handleSubmitDocument}
                            handleRemoveDoc={this.props.handleRemoveDocument}
                            handleSubmitResa={this.props.handleSubmitResa}
                        />

                    </Dialog>
                </Container>
            </div>
        )
    }
}

export default ContactInformations