import {PARTICIPANT_LIST_SUCCESS} from "../constants/actions";

const initialState = []

export default function participantsList(state = initialState, action) {
    switch (action.type) {
        case PARTICIPANT_LIST_SUCCESS:
            return action.participants
        default:
            return state
    }
}