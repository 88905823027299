import React from 'react'
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {loadTrainingList, loadTraining} from "../actions/trainings"
import moment from "moment";

class Training extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            trainingId: this.props.currentTraining["@id"] || "",
            multiDays: this.props.currentTraining.multiDays || false,
            currentDay: this.props.currentTraining.currentDay || null,
            dateStart: this.props.currentTraining.dateStart || null,
            dateEnd: this.props.currentTraining.dateEnd || null,
        }
    }

    componentDidMount() {
        if (this.props.trainingList.length === 0) {
            this.props.loadTrainingList()
        }
    }

    handleChangeTraining(e) {
        const selectedValue = e.target.value
        const selectedTraining = this.props.trainingList.find((training) => training["@id"] === selectedValue)

        if (selectedTraining !== undefined) {
            const days = selectedTraining.multiDays ? this.getDatesForSelect(selectedTraining.articlesStageStart, selectedTraining.articlesStageEnd) : []
            this.setState({
                trainingId: selectedTraining["@id"],
                multiDays: selectedTraining.multiDays,
                currentDay: days[0] ? days[0].value : null,
                dateStart: selectedTraining.articlesStageStart,
                dateEnd: selectedTraining.articlesStageEnd,
            })
        }
    }

    handleChangeDate(e) {
        this.setState({
            currentDay: e.target.value
        })
    }


    async handleClick() {
        if (this.state.trainingId.length > 0) {
            await this.props.loadTraining(this.state.trainingId, this.state.currentDay)
            this.props.history.push('/search')
        }
    }

    getDatesForSelect(startDateString, endDateString) {
        const currentDate = moment(startDateString, moment.ISO_8601)
        const endDate = moment(endDateString)
        const dates = [];

        while (currentDate <= endDate) {
            const value = currentDate.format('YYYY-MM-DD')
            const label = currentDate.format('LL')
            dates.push({value, label})
            currentDate.add(1, 'days')
        }

        return dates;
    }

    render() {
        const {trainingList} = this.props
        const days = this.state.multiDays ? this.getDatesForSelect(this.state.dateStart, this.state.dateEnd) : []

        return (
            <div className="mainArea flexCenterXY">
                <Container component="div" maxWidth="sm">
                    <Paper className="loginPaper">
                        <Typography component="h1" variant="h5" gutterBottom>
                            Sélectionnez votre stage
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="training-native-helper">Stage</InputLabel>
                            <NativeSelect
                                value={this.state.trainingId !== null ? this.state.trainingId : ''}
                                onChange={this.handleChangeTraining.bind(this)}
                                input={<Input name="training" id="training-native-helper" />}
                            >
                                <option key={0} value="" />
                                {trainingList.map(function(item) {
                                    return (
                                        <option key={item.articlesId} value={item["@id"]}>{item.articlesDesignation}</option>
                                    )
                                })}
                            </NativeSelect>

                        </FormControl>
                        { this.state.multiDays &&
                            <FormControl fullWidth margin={'normal'}>
                                <InputLabel htmlFor="training-date-native-helper">Date</InputLabel>
                                <NativeSelect
                                    value={this.state.currentDay !== null ? this.state.currentDay : ''}
                                    onChange={this.handleChangeDate.bind(this)}
                                    input={<Input name="training-date" id="training-date-native-helper" />}
                                >
                                    {days.map(function(item) {
                                        return (
                                            <option key={item.value} value={item.value}>{item.label}</option>
                                        )
                                    })}
                                </NativeSelect>
                            </FormControl>
                        }
                        <div className="mt2">
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={this.handleClick.bind(this)}
                            >
                                Sélectionner
                            </Button>
                        </div>
                    </Paper>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {trainingList, currentTraining} = state;
    return {
        trainingList,
        currentTraining
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadTrainingList: () => dispatch(loadTrainingList()),
        loadTraining: (id, day) => dispatch(loadTraining(id, day))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Training)