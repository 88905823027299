import {CURRENT_TRAINING_SUCCESS} from "../constants/actions";
import moment from "moment";

const initialState = {
    "@id": null,
    id: null,
    ref: "",
    prix: null,
    designation: "",
    dateStart: "",
    dateEnd: "",
    places: null,
    resas: null,
    commentaire: "",
    multiDays: false,
    options: [],
    trainingPrices: [],
    currentDay: null,
}

export default function currentTraining(state = initialState, action) {
    const {type: actionType, ...payload} = action
    switch (actionType) {
        case CURRENT_TRAINING_SUCCESS:
            if (payload.multiDays && payload.currentDay !== null) {
                const currentDay = moment(payload.currentDay)
                payload.trainingPrices = action.trainingPrices.filter( (trainingPrice) => {
                    return currentDay.isBetween(trainingPrice.dateStart, trainingPrice.dateEnd, undefined, '[]')
                })
            }

            return Object.assign({}, state, payload)
        default:
            return state;
    }
}