import moment from 'moment'

export function convertCsToObject(timeCs) {
    const timeMs = timeCs * 10

    const timeRef = moment.duration(timeMs)
    const timeInMin = timeRef.minutes()
    const timeInSec = timeRef.subtract(timeInMin, 'm').seconds()
    const timeInCs = Math.round(timeRef.subtract(timeInSec, 's').milliseconds() / 10)

    return {
        min: timeInMin,
        sec: timeInSec,
        cs: timeInCs
    }
}

export function convertObjectToCs(timeObject) {
    const timeRef = moment.duration(parseInt(timeObject.min, 10), 'm')
    timeRef.add(parseInt(timeObject.sec, 10), 's')
    timeRef.add(parseInt(timeObject.cs, 10) * 10, 'ms')
    return timeRef.asMilliseconds() / 10
}