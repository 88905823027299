import {TRAINING_LIST_SUCCESS} from "../constants/actions";

const initialState = []

export default function trainingList(state = initialState, action) {
    switch (action.type) {
        case TRAINING_LIST_SUCCESS:
            return action.trainings
        default:
            return state
    }
}