import { LOGIN_SUCCESS, LOGIN_FAILURE } from '../constants/actions'

const initialState = {
    isAuthenticated : false,
    username: '',
    token: '',
    refreshtoken: '',
    message: ''
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS :
            return Object.assign({}, state, {
                isAuthenticated: true,
                username: action.username,
                token: action.token,
                refreshtoken: action.refreshtoken,
                message: ''
            });

        case LOGIN_FAILURE :
            return Object.assign({}, state, {
                isAuthenticated: false,
                username: '',
                token: '',
                refreshtoken: '',
                message: action.message
            });

        default:
            return state
    }
}