import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class Alert extends React.Component{
    render() {
        return(
            <Dialog
                open={true}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Une erreur est survenue !"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { this.props.message }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary" autoFocus>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default Alert