import {
    CONFIG_BRANDS_SUCCESS, CONFIG_CIVILITIES_SUCCESS,
    CONFIG_COUNTRIES_SUCCESS,
    CONFIG_SUCCESS,
    CONFIG_TRACKS_SUCCESS
} from "../constants/actions";

const initialState = {
    loaded: false,
    brands: {},
    tracks: {},
    countries: {},
    civilities: {}
}

export default function config(state = initialState, action) {
    switch (action.type) {
        case CONFIG_SUCCESS:
            return Object.assign({}, state, {
                loaded: true
            })
        case CONFIG_BRANDS_SUCCESS:
            return Object.assign({}, state, {
                brands: action.brands
            })
        case CONFIG_COUNTRIES_SUCCESS:
            return Object.assign({}, state, {
                countries: action.countries
            })
        case CONFIG_TRACKS_SUCCESS:
            return Object.assign({}, state, {
                tracks: action.tracks
            })
        case CONFIG_CIVILITIES_SUCCESS:
            return Object.assign({}, state, {
                civilities: action.civilities
            })

        default:
            return state
    }
}