import {PARTICIPANT_RESET, PARTICIPANT_SUCCESS} from "../constants/actions";

const initialState = {
    "@id": null,
    resasId: null,
    resasValid: "Y",
    resasNom: "",
    resasPrenom: "",
    resasEmail: "",
    resasMobile: "",
    resasNaissance: null,
    assurance: null,
    assurancePolice: "",
    assuranceCompagnie: null,
    assuranceExpiration: null,
    resasMontant: 0,
    resasCommentaire: "",
    resasTimeRef: null,
    resasAlerte: "N",
    validePresence: false,
    transpondeur: null,
    transpReference: null,
    plateNumber: null,
    instructorComment: "",
    groupPriority: null,
    articles: "",
    resasCircuitsRef: null,
    clients: null,
    groupes: null,
    acheteur: null,
    mailing: "N",
    resasOpt: [],
    resasPayments: [],
    discharge: null,
    trainingStart: null,
    trainingEnd: null,
    trainingPrice: null
    // resasDoc:[]
}

export default function currentTraining(state = initialState, action) {
    switch (action.type) {
        case PARTICIPANT_SUCCESS:
            return Object.assign({}, state, {
                "@id": action.data["@id"],
                resasId: action.data.resasId,
                resasValid: action.data.resasValid,
                resasNom: action.data.resasNom,
                resasPrenom: action.data.resasPrenom,
                resasEmail: action.data.resasEmail,
                resasMobile: action.data.resasMobile,
                resasNaissance: action.data.resasNaissance,
                assurance: action.data.assurance,
                assurancePolice: action.data.assurancePolice,
                assuranceCompagnie: action.data.assuranceCompagnie,
                assuranceExpiration: action.data.assuranceExpiration,
                resasMontant: action.data.resasMontant,
                resasCommentaire: action.data.resasCommentaire,
                resasTimeRef: action.data.resasTimeRef,
                resasAlerte: action.data.resasAlerte,
                validePresence: action.data.validePresence,
                transpondeur: action.data.transpondeur,
                transpReference: action.data.transpReference,
                plateNumber: action.data.plateNumber,
                instructorComment: action.data.instructorComment,
                groupPriority: action.data.groupPriority,
                articles: action.data.articles,
                resasCircuitsRef: action.data.resasCircuitsRef,
                clients: action.data.clients,
                groupes: action.data.groupes,
                acheteur: action.data.acheteur,
                mailing: action.data.mailing,
                resasOpt: action.data.resasOpt || state.resasOpt,
                resasPayments: action.data.resasPayments || state.resasPayments,
                discharge: action.data.discharge,
                trainingStart: action.data.trainingStart,
                trainingEnd: action.data.trainingEnd,
                trainingPrice: action.data.trainingPrice
                // resasDoc: action.data.resasDoc || state.resasDoc
            })
        case PARTICIPANT_RESET:
            return initialState
        default:
            return state;
    }
}