import React from "react";
import PartialDocuments from "./partials/PartialDocuments";

class Documents extends React.Component{


    render() {
        return (
            <PartialDocuments
                currentParticipant={this.props.currentParticipant}
                clientDocs={this.props.clientDocs}
                handleSubmit={this.props.handleSubmitDocument}
                handleRemoveDoc={this.props.handleRemoveDocument}
                handleSubmitResa={this.props.handleSubmitResa}
            />
        )
    }
}

export default Documents