import React from 'react'
import {Switch, Redirect} from "react-router-dom";
import { PropsRoute } from '../components/Router';
import DashBoard from "./DashBoard";
import ContactInformations from "../components/ContactInformations";
import {connect} from "react-redux";
import moment from "moment";
import {updateParticipant, createParticipant, resetParticipant} from "../actions/participants";
import Licence from "../components/Licence";
import Options from "../components/Options";
import Documents from "../components/Documents";
import {uploadDocument, deleteDocument} from "../actions/documents";
import {addOption, removeOption} from "../actions/options";
import {loadClient, searchClients} from "../actions/clients";
import {addPayment, removePayment} from "../actions/payments";
import {MODE_CREATE} from "../constants/actions";
import Notes from "../components/Notes";

class DashBoardRoutes extends React.Component {
    handleSubmitResa(resa) {
        const resaFunction = this.props.mode === MODE_CREATE ? this.props.createParticipant : this.props.updateParticipant

        if (resa.assurance === 'ROUTE' || resa.assurance === 'FFM') {
            if (resa.assurance === 'ROUTE' && (!resa.assuranceCompagnie || resa.assuranceCompagnie.length === 0)) {
                return false
            }

            if (resa.assurance === 'FFM' && (resa.assurancePolice === null || resa.assurancePolice.length === 0 || !moment(resa.assurance_expiration).isValid())) {
                return false
            }
        }

        return resaFunction(resa).then(() => {
            if (resa.assurance === 'BMC') {
                const defaultOptionInsurance = this.props.currentTraining.options.find( item => {
                    return item.defaultInsurance === true
                })

                if (defaultOptionInsurance) {
                    const hasInsurance = this.props.currentParticipant.resasOpt.find( item => {
                        return item.stagesOpt === defaultOptionInsurance['@id']
                    })

                    if (!hasInsurance) {
                        this.handleSubmitOption({
                            resas: resa["@id"],
                            stagesOpt: defaultOptionInsurance['@id'],
                            resasOptQte: 1,
                            bookedDate: this.props.currentTraining.currentDay || '1970-01-01'
                        })
                    }
                }
            }
        })
    }

    handleSubmitDocument(document) {
        this.props.uploadDocument(document)
    }

    handleRemoveDocument(id) {
        this.props.deleteDocument(id)
    }

    handleSubmitOption(option) {
        this.props.addOption(option)
    }

    handleRemoveOption(option) {
        this.props.removeOption(option)
    }

    render() {
        return (
            <Switch>
                <Redirect exact from="/" to="/dashboard" component={DashBoard}/>
                <PropsRoute
                    exact path="/dashboard"
                    component={DashBoard}
                />
                <PropsRoute
                    exact path="/contact"
                    component={ContactInformations}
                    tracks={this.props.config.tracks}
                    currentTraining={this.props.currentTraining}
                    currentParticipant={this.props.currentParticipant}
                    handleSubmit={this.handleSubmitResa.bind(this)}
                    mode={this.props.mode}
                    searchClients={this.props.searchClients}
                    loadClient={this.props.loadClient}
                    clientDocs={this.props.clientDocs}
                    handleSubmitOption={this.handleSubmitOption.bind(this)}
                    handleRemoveOption={this.handleRemoveOption.bind(this)}
                    handleSubmitDocument={this.handleSubmitDocument.bind(this)}
                    handleRemoveDocument={this.handleRemoveDocument.bind(this)}
                />
                <PropsRoute
                    exact path="/licence"
                    component={Licence}
                    currentTraining={this.props.currentTraining}
                    currentParticipant={this.props.currentParticipant}
                    clientDocs={this.props.clientDocs}
                    handleSubmitResa={this.handleSubmitResa.bind(this)}
                    handleSubmitOption={this.handleSubmitOption.bind(this)}
                    handleRemoveOption={this.handleRemoveOption.bind(this)}
                    handleSubmitDocument={this.handleSubmitDocument.bind(this)}
                    handleRemoveDocument={this.handleRemoveDocument.bind(this)}
                />
                <PropsRoute
                    exact path="/options"
                    component={Options}
                    currentTraining={this.props.currentTraining}
                    currentParticipant={this.props.currentParticipant}
                    handleSubmit={this.handleSubmitOption.bind(this)}
                    handleRemove={this.handleRemoveOption.bind(this)}
                />
                <PropsRoute
                    exact path="/documents"
                    component={Documents}
                    currentParticipant={this.props.currentParticipant}
                    clientDocs={this.props.clientDocs}
                    handleSubmitDocument={this.handleSubmitDocument.bind(this)}
                    handleRemoveDocument={this.handleRemoveDocument.bind(this)}
                    handleSubmitResa={this.handleSubmitResa.bind(this)}
                />
                <PropsRoute
                    exact path="/notes"
                    component={Notes}
                    currentParticipant={this.props.currentParticipant}
                    currentTraining={this.props.currentTraining}
                    handleSubmitResa={this.handleSubmitResa.bind(this)}
                    resetParticipant={this.props.resetParticipant}
                    addPayment={this.props.addPayment}
                    removePayment={this.props.removePayment}
                />
                {/*<PropsRoute*/}
                {/*    exact path="/groupes"*/}
                {/*    component={Groupes}*/}
                {/*    participantsList={this.props.participantsList}*/}
                {/*    // currentParticipant={this.props.currentParticipant}*/}
                {/*    // handleSubmitResa={this.handleSubmitResa.bind(this)}*/}
                {/*/>*/}
            </Switch>
        )
    }

}

function mapStateToProps(state) {
    const {config, currentTraining, currentParticipant, mode, participantsList, clientDocs} = state;
    return {
        config,
        currentTraining,
        currentParticipant,
        mode,
        participantsList,
        clientDocs
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateParticipant: (resa, history) => dispatch(updateParticipant(resa, history)),
        createParticipant: (resa, history) => dispatch(createParticipant(resa, history)),
        uploadDocument: document => dispatch(uploadDocument(document)),
        deleteDocument: id => dispatch(deleteDocument(id)),
        addOption: option => dispatch(addOption(option)),
        removeOption: id => dispatch(removeOption(id)),
        searchClients: inputValue => dispatch(searchClients(inputValue)),
        loadClient: id => dispatch(loadClient(id)),
        resetParticipant: () => dispatch(resetParticipant()),
        addPayment: payment => dispatch(addPayment(payment)),
        removePayment: id => dispatch(removePayment(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardRoutes)
