import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import {split_groups} from "../tools/groups"
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

class Groupes extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            nbgroups : "",
            groups: []
        }
    }

    handleSplitParticipants(e) {
        const nombreGroupes = e.target.value

        this.setState({
            nbgroups: nombreGroupes,
            groups: split_groups(this.props.participantsList, nombreGroupes)
        })
    }

    render() {
        const nombreGroupes = [1,2,3,4,5,6,7,8]

        return (
            <div className="mainArea flexCenterXY">
                <Container component="div" maxWidth="md">
                    <Paper className="formPaper">
                        <Typography component="h1" variant="h5" gutterBottom>
                            Répartition des groupes
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="group-split-native-helper">Nombre de groupes</InputLabel>
                            <NativeSelect
                                value={this.state.nbgroups}
                                onChange={this.handleSplitParticipants.bind(this)}
                                input={<Input name="group-split" id="group-priority-native-helper" />}
                            >
                                <option value="" />
                                {nombreGroupes.map(function(item, idx) {
                                    return (
                                        <option key={idx} value={item}>{item}</option>
                                    )
                                })}
                            </NativeSelect>
                        </FormControl>
                        <Divider />

                        {this.state.groups.map(function (participants, idx) {
                            return (
                                <React.Fragment key={`groupe${idx}`}>
                                    <Typography component="h1" variant="h5" className={"mt4 txtcenter"} gutterBottom>
                                        Groupe {String.fromCharCode(idx + 65)}
                                    </Typography>

                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Transpondeur</TableCell>
                                                <TableCell align="center">Nom</TableCell>
                                                <TableCell align="center">Prénom</TableCell>
                                                {/*<TableCell align="center">Temps Ref.</TableCell>*/}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {participants.map( (participant, idx) => (
                                                <TableRow key={participant['@id']}>
                                                    <TableCell component="th" scope="row">{participant.transpondeur || '-'}</TableCell>
                                                    <TableCell align="center">{participant.resasNom || '-'}</TableCell>
                                                    <TableCell align="center">{participant.resasPrenom || '-'}</TableCell>
                                                    {/*<TableCell align="right">{participant.absoluteTimeRef}</TableCell>*/}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </React.Fragment>
                            )
                        })}

                    </Paper>
                </Container>
            </div>
        )
    }
}

export default Groupes