import {MODE_EDIT, MODE_UPDATE} from "../constants/actions";

const initialState = MODE_EDIT

export default function mode(state = initialState, action) {
    switch (action.type) {
        case MODE_UPDATE:
            return action.mode
        default:
            return state
    }
}