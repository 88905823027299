import {
    CONFIG_SUCCESS,
    AJAX_FAILURE,
    AJAX_REQUEST,
    AJAX_SUCCESS,
    // CONFIG_BRANDS_SUCCESS,
    // CONFIG_COUNTRIES_SUCCESS,
    CONFIG_TRACKS_SUCCESS,
    // CONFIG_CIVILITIES_SUCCESS
} from "../constants/actions";
import {batch} from 'react-redux'

export function loadConfig() {
    return (dispatch) => {
        batch(() => {
            // dispatch(loadBrands())
            // dispatch(loadCountries())
            dispatch(loadTracks())
            // dispatch(loadCivilities())
            dispatch({type: CONFIG_SUCCESS})
        })
    }
}
/*

function loadBrands() {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        fetch(`${process.env.REACT_APP_API_URL}/marques`, {
            method: 'GET',
            withCredentials: true,
            headers
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            // const brands = data.map(function (brand) {
            //     return {value: brand.marquesId, label: brand.marquesName}
            // })
            dispatch(Object.assign({}, {type: CONFIG_BRANDS_SUCCESS}, {brands: data["hydra:member"]}));
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}
*/
/*

function loadCountries() {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        fetch(`${process.env.REACT_APP_API_URL}/pays`, {
            method: 'GET',
            withCredentials: true,
            headers
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            // const countries = data.map(function (country) {
            //     return {value: country.paysId, label: country.paysName}
            // })
            const countries = data["hydra:member"]
            const index = countries.findIndex( country => country.paysName === 'France')
            if (index !== -1) {
                const country = countries.splice(index, 1)
                countries.unshift(country[0])
            }
            dispatch(Object.assign({}, {type: CONFIG_COUNTRIES_SUCCESS}, {countries}));
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}
*/

function loadTracks() {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        fetch(`${process.env.REACT_APP_API_URL}/circuits`, {
            method: 'GET',
            withCredentials: true,
            headers
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            // const tracks = data.map(function (track) {
            //     return {value: track.circuitsId, label: track.circuitsName}
            // })
            dispatch(Object.assign({}, {type: CONFIG_TRACKS_SUCCESS}, {tracks: data["hydra:member"]}));
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}
/*

function loadCivilities() {
    return (dispatch, getState) => {
        const store = getState()

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/ld+json');
        headers.append('Authorization', `Bearer ${store.auth.token}`)

        dispatch({type: AJAX_REQUEST})
        fetch(`${process.env.REACT_APP_API_URL}/civilities`, {
            method: 'GET',
            withCredentials: true,
            headers
        }).then(response => {
            dispatch({type: AJAX_SUCCESS})
            if(response.ok) {
                return response.json()
            } else {
                throw new Error(`Erreur ${response.status} : ${response.statusText}`)
            }
        }).then( data => {
            // const civilities = data.map(function (civility) {
            //     return {value: civility.civilityId, label: civility.civilityName}
            // })
            dispatch(Object.assign({}, {type: CONFIG_CIVILITIES_SUCCESS}, {civilities: data["hydra:member"]}));
        }).catch((error) => {
            dispatch(Object.assign({}, {type: AJAX_FAILURE}, {message: error.message}))
        });
    }
}*/
