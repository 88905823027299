import React from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[800],
    },
    imgFull: {
        margin: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
    }
});


class LightBox extends React.Component{
    render() {
        return (
            <Dialog fullScreen open={this.props.open} onClose={this.props.handleClose}>
                <IconButton aria-label="close" className={this.props.classes.closeButton} onClick={this.props.handleClose}>
                    <CloseIcon />
                </IconButton>
                <img src={this.props.src} className={this.props.classes.imgFull} alt={""}/>
            </Dialog>
        )
    }
}

export default withStyles(styles)(LightBox)