
// Login
// export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'

// Config
export const CONFIG_REQUEST = 'CONFIG_REQUEST'
export const CONFIG_SUCCESS = 'CONFIG_SUCCESS'
export const CONFIG_BRANDS_SUCCESS = 'CONFIG_BRANDS_SUCCESS'
export const CONFIG_COUNTRIES_SUCCESS = 'CONFIG_COUNTRIES_SUCCESS'
export const CONFIG_TRACKS_SUCCESS = 'CONFIG_TRACKS_SUCCESS'
export const CONFIG_CIVILITIES_SUCCESS = 'CONFIG_CIVILITIES_SUCCESS'

// Ajax loading
export const AJAX_REQUEST = 'AJAX_REQUEST'
export const AJAX_SUCCESS = 'AJAX_SUCCESS'
export const AJAX_FAILURE = 'AJAX_FAILURE'
export const AJAX_RESET = 'AJAX_RESET'

// Current Training
export const CURRENT_TRAINING_SUCCESS = 'CURRENT_TRAINING_SUCCESS'

// Training List
export const TRAINING_LIST_SUCCESS = 'TRAINING_LIST_SUCCESS'

// Participant List
export const PARTICIPANT_LIST_SUCCESS = 'PARTICIPANT_LIST_SUCCESS'

// Participant
export const PARTICIPANT_SUCCESS = 'PARTICIPANT_SUCCESS'
export const PARTICIPANT_RESET = 'PARTICIPANT_RESET'

// docs
export const CLIENT_DOC_SUCCESS = 'CLIENT_DOC_SUCCESS'
export const CLIENT_DOC_RESET = 'CLIENT_DOC_RESET'


// Mode
export const MODE_UPDATE = 'MODE_UPDATE'
export const MODE_CREATE = 'MODE_CREATE'
export const MODE_EDIT = 'MODE_EDIT'
