import React from "react";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import RemoveCircle from "@material-ui/icons/RemoveCircle"
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import equal from 'fast-deep-equal'

class PartialOptions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            option: "",
            qte: 1,
            availableOptions: this.getAvalaibleOptions(),
            openAlert: false,
            removeOption: ""
        }
    }

    componentDidUpdate(prevProps) {
        if (!equal(this.props.currentTraining.options, prevProps.currentTraining.options)) {
            this.setState({
                option: "",
                qte: 1,
                availableOptions: this.getAvalaibleOptions()
            })
        }

    }

    getAvalaibleOptions() {
        return this.props.currentTraining.options.map( (row, idx) => {
            return {
                "@id": row["@id"],
                name: row.articles.articlesDesignation,
                total: row.stagesOptNbdispos,
                booked: row.stagesOptNbresas,
                price: row.stagesOptPrix,
                priceType: row.stagesOptPrixType === 0 ? "€" : "%",
                key: idx + 1
            }
        })
    }

    handleChange(key, e) {
        this.setState({
            [key]: e.target.value
        })
    }

    handleCloseAlert() {
        this.setState({
            openAlert: false
        })
    }

    handleOpenDialogRemove(id) {
        this.setState({
            removeOption: id
        })
    }

    handleCloseDialogRemove() {
        this.setState({
            removeOption: ""
        })
    }

    handleRemoveOption() {
        this.props.handleRemove(this.state.removeOption)
        this.handleCloseDialogRemove()
    }

    handleSubmit() {
        const option = this.state.availableOptions.find(item => item["@id"] === this.state.option)

        if (this.state.qte > (option.total - option.booked)) {
            this.setState({
                openAlert: true
            })
        } else if (this.state.option && this.state.qte && parseInt(this.state.qte, 10) > 0) {
            this.props.handleSubmit({
                resas: this.props.currentParticipant["@id"],
                stagesOpt: this.state.option,
                resasOptQte: parseInt(this.state.qte, 10),
                bookedDate: this.props.currentTraining.currentDay || '1970-01-01'
            })
        }
    }

    render() {
        const optionsList = this.props.currentParticipant.resasOpt.map( row => {
            const option = this.state.availableOptions.find(function (item) {
                return item["@id"] === row.stagesOpt
            })

            return {
                key: row["@id"],
                name: option.name,
                qte: row.resasOptQte,
                price: option.price,
                priceType: option.priceType
            }
        })

        const availableOptions = this.state.availableOptions.filter( row => {
            return row.priceType === "€"
        })

        return(
            <React.Fragment>
                <Typography component="h1" variant="h5" gutterBottom>
                    Options
                </Typography>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Option</TableCell>
                            <TableCell align="center">Qté</TableCell>
                            <TableCell align="center">Prix</TableCell>
                            <TableCell align="center">Supprimer</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {optionsList.map((row, idx) => (
                            <TableRow key={idx}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="center">{row.qte}</TableCell>
                                <TableCell align="center">{row.price}{row.priceType}</TableCell>
                                <TableCell align="center">
                                    {row.priceType === "€" &&
                                        <Button color="inherit">
                                            <RemoveCircle onClick={this.handleOpenDialogRemove.bind(this, row.key)}/>
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Dialog
                    open={this.state.removeOption.length > 0}
                    onClose={this.handleCloseDialogRemove.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Confirmer la suppression de cette option ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialogRemove.bind(this)} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={this.handleRemoveOption.bind(this)} color="secondary" autoFocus>
                            Confirmer suppression
                        </Button>
                    </DialogActions>
                </Dialog>
                <Divider/>
                <div className="mt2">
                    <Typography component="h4" gutterBottom>
                        Ajouter des options
                    </Typography>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="newoption-native-helper">Option</InputLabel>
                        <NativeSelect
                            value={this.state.option}
                            onChange={this.handleChange.bind(this, 'option')}
                            input={<Input name="trackref" id="newoption-native-helper"/>}
                        >
                            <option key={0} value=""/>
                            {availableOptions.map(function (item) {
                                return (
                                    <option key={item.key} value={item['@id']}
                                            disabled={item.total - item.booked < 1}>{item.name} - {item.price}€
                                        ({item.total - item.booked})</option>
                                )
                            })}
                        </NativeSelect>
                    </FormControl>
                    <TextField
                        id="newoption-qte"
                        label="Quantité"
                        value={this.state.qte}
                        onChange={this.handleChange.bind(this, 'qte')}
                        margin="normal"
                        type="number"
                        fullWidth
                    />
                    <div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={this.handleSubmit.bind(this)}
                        >
                            Ajouter
                        </Button>
                    </div>
                </div>

                <Dialog
                    open={this.state.openAlert}
                    onClose={this.handleCloseAlert.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Il n'y a pas assez de disponibilité sur cette option pour cette quantité
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseAlert.bind(this)} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default PartialOptions