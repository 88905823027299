import React from 'react'
import {withStyles} from '@material-ui/styles'
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import logo from '../assets/images/logo-black.svg'
import Paper from "@material-ui/core/Paper"
import {useStyles} from "../theme";
import {login} from "../actions/auth"
import {compose} from "redux";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: ''
        }
    }

    handleChangeUsername(event) {
        this.setState({
            username: event.target.value
        })
    }

    handleChangePassword(event) {
        this.setState({
            password: event.target.value
        })
    }

    handleClick() {
        const {username, password} = this.state;
        this.props.login(username, password)
    }

    render() {
        const {classes} = this.props

        return (
            this.props.auth.isAuthenticated ? (
                <Redirect
                    to={{
                        pathname: "/",
                        state: {from: this.props.location}
                    }}
                />
            ) : (
                <div className="flexCenterXYFull">
                    <Container component="div" maxWidth="xs">
                        <Paper className="loginPaper">
                            <img src={logo} className={classes.logoBlack} alt="logo"/>
                            <Typography component="h1" variant="h5" gutterBottom>
                                Veuillez vous identifier
                            </Typography>
                            {this.props.auth.message.length > 0 &&
                            <Typography component="div" color="error" gutterBottom>
                                {this.props.auth.message}
                            </Typography>
                            }
                            <div>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Identifiant"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                    onChange={this.handleChangeUsername.bind(this)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Mot de passe"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={this.handleChangePassword.bind(this)}
                                />
                                <div className={classes.mt2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={this.handleClick.bind(this)}
                                    >
                                        S'identifier
                                    </Button>
                                </div>
                            </div>
                        </Paper>
                    </Container>
                </div>
            )
        )
    }
}

function mapStateToProps(state) {
    const {auth} = state;
    return {
        auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: (username, password) => dispatch(login(username, password))
    }
}

export default compose(withStyles(useStyles), connect(mapStateToProps, mapDispatchToProps))(Login)