// React
import React from 'react';
import ReactDOM from 'react-dom';

// Redux
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider as ReduxProvider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import { PersistGate } from 'redux-persist/integration/react'
import reducer from './reducers'

// Material UI
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import {theme} from './theme'

// Auth
// import {importToken} from './actions/auth'
// import {TOKEN_NAME} from './constants/storage'

// App
import App from './App';

// Service Worker
import * as serviceWorker from './serviceWorker';


// Redux store creation
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'trainingList', 'currentTraining']
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(persistedReducer, composeEnhancers(
    applyMiddleware(thunkMiddleware)
))

const persistor = persistStore(store)

// Get session token
// const token = sessionStorage.getItem(TOKEN_NAME);
// if (token !== null && token !== "undefined") {
//     store.dispatch(importToken(token))
// }

// Launch App
ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </ReduxProvider>
    </ThemeProvider>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
