import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Camera, { IMAGE_TYPES, FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import Visibility from "@material-ui/icons/Visibility";
import Table from "@material-ui/core/Table";
import LightBox from "../LightBox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

class PartialDocuments extends React.Component{
    constructor(props) {
        super(props)

        this.state = {
            openCam: false,
            currentPhoto: null,
            description: "",
            expiration: "",
            previewUrl: "",
            removeDocId: ""
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.clientDocs.length < this.props.clientDocs.length) {
            this.handleReset()
        }
    }

    handleOpenCam() {
        this.setState({
            openCam: !this.state.openCam
        })
    }

    onTakePhoto(dataUri) {
        this.setState({
            currentPhoto: dataUri
        })
        this.handleOpenCam()
    }

    handleChange(key, e) {
        this.setState({
            [key]: e.target.value
        })
    }

    handleSubmit() {
        this.props.handleSubmit({
            file: this.state.currentPhoto,
            description: this.state.description,
            // resa: this.props.currentParticipant.resasId,
            expiration: moment(this.state.expiration).isValid() ? moment(this.state.expiration).format('YYYY-MM-DD') : null,
            key: this.props.clientDocs.length + 1
        })
    }

    handleReset() {
        this.setState({
            currentPhoto: null,
            description: "",
            expiration: ""
        })
    }

    handlePreviewDoc(url) {
        this.setState({
            previewUrl: url.length > 0 ? `${process.env.REACT_APP_API_BASEURL}${url}` : ""
        })
    }

    handleRemoveDoc() {
        this.props.handleRemoveDoc(this.state.removeDocId)
        this.setState({
            removeDocId: ""
        })
    }

    handleOpenDialogRemove(id) {
        this.setState({
            removeDocId: id
        })
    }

    handleCloseDialogRemove() {
        this.setState({
            removeDocId: ""
        })
    }

    handleChangeDischarge (e) {
        this.props.handleSubmitResa(Object.assign({}, this.props.currentParticipant, {discharge: e.target.checked ? moment(new Date(new Date().getFullYear(), 11, 31)).format('YYYY-MM-DD') : null}))
    }

    checkValidDischarge() {
        const dischargeDate = moment(this.props.currentParticipant.discharge)
        if (dischargeDate.isValid() && dischargeDate.isAfter(moment())) {
            return true
        }
        return false
    }

    render() {
        return (
            <div className="mainArea flexCenterXY">
                <Container component="div" maxWidth="md">
                    <Paper className="formPaper">
                        <Typography component="h1" variant="h5" gutterBottom>
                            Documents
                        </Typography>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Document</TableCell>
                                    <TableCell>Expiration</TableCell>
                                    <TableCell align="center">Voir</TableCell>
                                    <TableCell align="center">Supprimer</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.clientDocs.map( (row, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell component="th" scope="row">
                                            {row.description}
                                        </TableCell>
                                        <TableCell>
                                            {row.expiration ? moment(row.expiration).format('DD/MM/YYYY') : '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button color="inherit" onClick={this.handlePreviewDoc.bind(this, row.contentUrl)}>
                                                <Visibility />
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button color="inherit">
                                                <RemoveCircle onClick={this.handleOpenDialogRemove.bind(this, row["@id"])} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        <LightBox
                            open={this.state.previewUrl.length > 0}
                            handleClose={this.handlePreviewDoc.bind(this, "")}
                            src={this.state.previewUrl}
                        />

                        <Dialog
                            open={this.state.removeDocId.length > 0}
                            onClose={this.handleCloseDialogRemove.bind(this)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Confirmer la suppression de ce document ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseDialogRemove.bind(this)} color="primary">
                                    Annuler
                                </Button>
                                <Button onClick={this.handleRemoveDoc.bind(this)} color="secondary" autoFocus>
                                    Confirmer suppression
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Divider />

                        <div className="mt2">
                            <Typography component="h4" gutterBottom>
                                Ajouter un document
                            </Typography>
                            { !this.state.currentPhoto &&
                                <React.Fragment>
                                    <div>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            size="large"
                                            onClick={this.handleOpenCam.bind(this)}
                                        >
                                            Prendre en photo un document
                                        </Button>
                                    </div>
                                    <div className={"mt2"}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.checkValidDischarge()}
                                                    onChange={this.handleChangeDischarge.bind(this)}
                                                    value="discharge"
                                                    color="primary"
                                                />
                                            }
                                            label="Décharge annuelle signée"
                                        />
                                    </div>

                                </React.Fragment>
                            }
                            { this.state.openCam &&
                            <div className="ajaxLoaderContainer">
                                <Camera
                                    onTakePhoto = { (dataUri) => { this.onTakePhoto(dataUri); } }
                                    isFullscreen={true}
                                    imageType={IMAGE_TYPES.JPG}
                                    imageCompression={0.8}
                                    idealFacingMode = {FACING_MODES.ENVIRONMENT}
                                    isImageMirror={false}
                                />
                            </div>
                            }
                            {
                                this.state.currentPhoto &&
                                <div className={"mt2"}>
                                    <p style={{textAlign: 'center'}}>
                                        <img src={this.state.currentPhoto} style={{maxWidth: '300px'}} alt={""}/>
                                    </p>
                                    <TextField
                                        id="newdocument-description"
                                        label="Description du document"
                                        value={this.state.description}
                                        onChange={this.handleChange.bind(this, 'description')}
                                        margin="normal"
                                        error={this.state.description.length === 0}
                                        fullWidth
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="newdocument-native-helper">Préremplir la description avec</InputLabel>
                                        <NativeSelect
                                            value={""}
                                            onChange={this.handleChange.bind(this, 'description')}
                                            input={<Input name="trackref" id="newdocument-native-helper" />}
                                        >
                                            <option key={0} value="" />
                                            <option key={1} value={"Décharge"}>Décharge</option>
                                            <option key={2} value={"Remise Michelin"}>Remise Michelin</option>
                                            <option key={3} value={"Permis de conduire"}>Permis de conduire</option>
                                        </NativeSelect>
                                    </FormControl>

                                    <TextField
                                        id="newdocument-expiration"
                                        label="Date d'expiration"
                                        type="date"
                                        value={this.state.expiration}
                                        className={'mt2'}
                                        onChange={this.handleChange.bind(this, 'expiration')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />

                                    <div className={"mt2"}>
                                        <Grid container justifyContent="center" spacing={2}>
                                            <Grid item  xs={6}>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    onClick={this.handleSubmit.bind(this)}
                                                >
                                                    Ajouter
                                                </Button>
                                            </Grid>
                                            <Grid item  xs={6}>
                                                <Button
                                                    type="button"
                                                    fullWidth
                                                    variant="contained"
                                                    color="secondary"
                                                    size="large"
                                                    onClick={this.handleReset.bind(this)}
                                                >
                                                    Annuler
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            }
                        </div>
                    </Paper>
                </Container>
            </div>
        )
    }
}

export default PartialDocuments