import React from 'react'
import {connect} from "react-redux";
import AjaxLoader from "../components/AjaxLoader";
import Alert from "../components/Alert";
import {AJAX_RESET} from "../constants/actions";

class DialogBox extends React.Component{

    handleClose() {
        this.props.closeDialog()
    }

    render() {
        return(
            <React.Fragment>
                { this.props.dialog.loading &&
                    <AjaxLoader />
                }
                { this.props.dialog.error &&
                    <Alert
                        message={this.props.dialog.message}
                        handleClose={this.handleClose.bind(this)}
                    />
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const {dialog} = state;
    return {
        dialog
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeDialog: () => dispatch({type: AJAX_RESET})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogBox)