import React from 'react';
// import logo from './logo.svg';
// import './App.css';

import PrivateArea from './containers/PrivateArea'
import Login from './containers/Login'

import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import DialogBox from "./containers/DialogBox";

function App() {
  return (
      <Router>
        <div className="App">
          <PrivateArea />
          <Route path="/login" component={Login} />
          <DialogBox />
        </div>
      </Router>
  );
}

export default App;
