import React from 'react'
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import {useStyles} from "../theme";
import {compose} from "redux";
import {withStyles} from '@material-ui/styles'
import {connect} from "react-redux";
import ReportProblem from '@material-ui/icons/ReportProblem'
import classNames from 'classnames'
import {MODE_EDIT} from "../constants/actions";
import { loadParticipantsList, resetResa} from "../actions/participants";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

class DashBoard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            buttonCoord: "",
            dialogReset: false
        }
    }

    redirect(path) {
        this.props.history.push(path);
    }

    checkButtonCoord() {
        if (
            this.props.currentParticipant.clients === null ||
            !this.props.currentParticipant.resasNom ||
            !this.props.currentParticipant.resasPrenom ||
            !this.props.currentParticipant.resasEmail ||
            this.props.currentParticipant.assurance === null
        )
        {
            return "danger"
        }

        if (!this.props.currentParticipant.resasCircuitsRef || !this.props.currentParticipant.resasTimeRef) {
            return 'warning'
        }

        return ""
    }

    noCheckButton() {
        return ""
    }

    getButton(label, path, checkCallback, onclickCallback = null) {
        const status = checkCallback()
        const {classes} = this.props

        return (
            <Button
                fullWidth
                variant="contained"
                size="large"
                className={classNames(classes.dashboardButton, {[classes.dashboardDangerButton]: status === 'danger', [classes.dashboardWarningButton]: status === 'warning'})}
                onClick={onclickCallback === null ? this.redirect.bind(this, path) : onclickCallback.bind(this, path)}
            >
                { status.length > 0 &&
                    <ReportProblem className={classes.dashboardWarningIcon} />
                }
                {label}
            </Button>
        )
    }

    reloadParticipantsList(path) {
        this.props.loadParticipantsList().then(() => {
            this.redirect(path)
        })
    }

    handleShowDialog(status) {
        this.setState({
            dialogReset: status
        })
    }

    handleConfirmReset() {
        this.props.resetResa().then(() => {
            this.redirect('/contact')
        });
    }

    render () {
        const {classes} = this.props
        return (
            <div className="mainArea flexCenterXY">
                <Container component="div" maxWidth="md">
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item  xs={6}>
                            {this.getButton('Infos réservation', '/contact', this.checkButtonCoord.bind(this))}
                        </Grid>
                        {this.props.mode === MODE_EDIT &&
                            <React.Fragment>
                                <Grid item xs={6}>
                                    {this.getButton('Documents', '/documents', this.noCheckButton.bind(this))}
                                </Grid>
                                <Grid item xs={6}>
                                    {this.getButton('Valider la réservation', '/notes', this.noCheckButton.bind(this))}
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        size="large"
                                        className={classNames(classes.dashboardButton, classes.dashboardWarningButton)}
                                        onClick={this.handleShowDialog.bind(this, true)}
                                    >
                                        Réinitialiser réservation
                                    </Button>
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item  xs={6}>
                            {this.getButton('Répartition groupes', '/groupes', this.noCheckButton.bind(this), this.reloadParticipantsList)}
                        </Grid>
                        <Grid item  xs={6}>
                            {this.getButton('Liste des transpondeurs', '/transponders', this.noCheckButton.bind(this), this.reloadParticipantsList)}
                        </Grid>
                    </Grid>
                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        maxWidth="xs"
                        // onEntering={handleEntering}
                        aria-labelledby="confirmation-dialog-title"
                        open={this.state.dialogReset}
                    >
                        <DialogTitle id="confirmation-dialog-title">Réinitialiser réservation</DialogTitle>
                        <DialogContent dividers>
                            Voulez-vous vraiment réinitialiser cette réservation ?
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={this.handleShowDialog.bind(this, false)} color="primary">
                                Annuler
                            </Button>
                            <Button onClick={this.handleConfirmReset.bind(this)} color="primary">
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {currentParticipant, mode} = state;
    return {
        currentParticipant,
        mode
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadParticipantsList: () => dispatch(loadParticipantsList()),
        resetResa: () => dispatch(resetResa())
    }
}

export default compose(withStyles(useStyles), connect(mapStateToProps, mapDispatchToProps))(DashBoard)
